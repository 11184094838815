
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLButtonElement | null>(null);

    try {
      localStorage.removeItem("terms-seen-in-session");
    } catch (err) {
      console.error(err);
    }

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().required().label("Password"),
    });

    if (route?.query?.t) {
      ApiService.post(`/auth/verify-email?token=${route.query.t}`, null)
        .then(res => {
          Swal.fire({
            title: "Account confirmed!",
            text: "You can now login to your account",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Email verification failed",
            text: "Did you properly copy your account confirmation link, or is your account already confirmed?",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Go to login",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        });
    }

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          // Go to page after successfully login (with full reload to force JWT refresh)
          // router.push({
          //   name: JwtService.isMarketplaceOnly()
          //     ? "marketplace"
          //     : "dashboard"
          // });
          window.location.href = JwtService.isMarketplaceOnly()
              ? "/marketplace"
              : "/dashboard";
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Invalid credentials!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
